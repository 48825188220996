import "core-js/modules/es6.regexp.match";
import "core-js/modules/es6.regexp.constructor";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.search";
import "core-js/modules/es6.typed.uint8-array";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.to-string";
import _typeof from "D:/third/openy-wxms-web2021-01-12/node_modules/@babel/runtime/helpers/esm/typeof";

/* eslint-disable no-mixed-spaces-and-tabs */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }

  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;

  if (typeof time === 'undefined' || time === null || time === 'null') {
    return '';
  } else if (_typeof(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }

    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }

    date = new Date(time);
  }

  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, function (result, key) {
    var value = formatObj[key]; // Note: getDay() returns 0 on Sunday

    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }

    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }

    return value || 0;
  });
  return time_str;
}
/**
 * 将时间戳转换为202009180951格式
 */

export function timeFormat(timestamp) {
  //timestamp是整数，否则要parseInt转换,不会出现少个0的情况
  var time = new Date(timestamp);
  var year = time.getFullYear();
  var month = time.getMonth() + 1;
  var date = time.getDate();
  var hours = time.getHours();
  var minutes = time.getMinutes();
  var seconds = time.getSeconds();
  return '' + year + month + date + hours + minutes + seconds;
}
/**
 * 将时间戳转换为2020年10月20日格式
 */

export function timeFormat2(timestamp) {
  //timestamp是整数，否则要parseInt转换,不会出现少个0的情况
  var time = new Date(timestamp);
  var year = time.getFullYear();
  var month = time.getMonth() + 1;
  var date = time.getDate();
  var hours = time.getHours();
  var minutes = time.getMinutes();
  var seconds = time.getSeconds();
  return '' + year + '年' + month + '月' + date + '日';
}
/**
 * 将时间戳转换为2020-10-20 10:30 格式
 */

export function timeFormat3(timestamp) {
  //timestamp是整数，否则要parseInt转换,不会出现少个0的情况
  var time = new Date(timestamp);
  var year = time.getFullYear();
  var month = time.getMonth() + 1;
  var date = time.getDate();
  var hours = time.getHours();
  var minutes = time.getMinutes();
  var seconds = time.getSeconds();

  if (hours < 10) {
    hours = '0' + hours;
  }

  if (minutes < 10) {
    minutes = '0' + minutes;
  }

  if (month < 10) {
    month = "0" + month;
  }

  if (date < 10) {
    date = "0" + date;
  }

  return '' + year + '-' + month + '-' + date + ' ' + hours + ':' + minutes;
}
/**
 * 将时间戳转换为2020/10/20 10:30:00 格式
 */

export function timeFormat10(timestamp) {
  //timestamp是整数，否则要parseInt转换,不会出现少个0的情况
  var time = new Date(timestamp);
  var year = time.getFullYear();
  var month = time.getMonth() + 1;
  var date = time.getDate();
  var hours = time.getHours();
  var minutes = time.getMinutes();
  var seconds = time.getSeconds();

  if (hours < 10) {
    hours = '0' + hours;
  }

  if (minutes < 10) {
    minutes = '0' + minutes;
  }

  return '' + year + '/' + month + '/' + date + ' ' + hours + ':' + minutes + ":" + seconds;
}
/**
 * 将时间戳转换为2020-10-20 格式
 */

export function timeFormat4(timestamp) {
  //timestamp是整数，否则要parseInt转换,不会出现少个0的情况
  var time = new Date(timestamp);
  var year = time.getFullYear();
  var month = time.getMonth() + 1;
  var date = time.getDate();
  var hours = time.getHours();
  var minutes = time.getMinutes();
  var seconds = time.getSeconds();
  return '' + year + '-' + month + '-' + date;
}
/**
 * 将时间戳转换为10月20日格式
 */

export function timeFormat5(timestamp) {
  //timestamp是整数，否则要parseInt转换,不会出现少个0的情况
  var time = new Date(timestamp);
  var month = time.getMonth() + 1;
  var date = time.getDate();
  return '' + month + '月' + date + '日';
}
/**
 * 时间戳转为几小时前等（详细）
 * @param {Number} dateTimeStamp
 * @param {Number} sysTime
 */

export function timeFormat6(dateTimeStamp, sysTime) {
  var minute = 1000 * 60;
  var hour = minute * 60;
  var day = hour * 24;
  var halfamonth = day * 15;
  var month = day * 30;
  var year = day * 365;
  var diffValue = sysTime - dateTimeStamp;
  var result;

  if (diffValue < 0) {
    return;
  }

  var dayC = diffValue / day;
  var minuteC = parseInt(diffValue / minute);
  var hourC = parseInt(diffValue / hour);
  var date = new Date(dateTimeStamp);
  var rYear = date.getFullYear();
  var rMonth = date.getMonth() + 1;
  var rDay = date.getDate();
  var rHour = date.getHours();
  var rMinute = date.getMinutes();

  if (rMonth < 10) {
    //月数补0
    rMonth = "0" + rMonth;
  }

  if (rDay < 10) {
    //天数补0
    rDay = "0" + rDay;
  }

  if (rHour < 10) {
    //小时数补0
    rHour = "0" + rHour;
  }

  if (rMinute < 10) {
    //分钟数补0
    rMinute = "0" + rMinute;
  }

  if (minuteC < 1) {
    result = "刚刚";
  } else if (minuteC < 60) {
    result = "".concat(minuteC, "\u5206\u949F\u524D");
  } else if (dayC < 1) {
    result = "".concat(hourC, "\u5C0F\u65F6\u524D");
  } else if (dayC < 365) {
    result = "".concat(rMonth, "-").concat(rDay, " ").concat(rHour, ":").concat(rMinute);
  } else {
    result = "".concat(rYear, "/").concat(rMonth, "/").concat(rDay, " ").concat(rHour, ":").concat(rMinute);
  }

  return result;
}
/**
 * 时间戳转为几小时前等（详细）
 * @param {Number} timestamp
 * 08月25日 14:00
 */

export function timeFormat7(timestamp) {
  var time = new Date(timestamp);
  var year = time.getFullYear();
  var month = time.getMonth() + 1;
  var date = time.getDate();
  var hours = time.getHours();
  var minute = time.getMinutes();
  var second = time.getSeconds();

  if (month < 10) {
    month = '0' + month;
  }

  if (date < 10) {
    date = '0' + date;
  }

  if (hours < 10) {
    hours = '0' + hours;
  }

  if (minute < 10) {
    minute = '0' + minute;
  }

  if (second < 10) {
    second = '0' + second;
  }

  return month + '月' + date + '日 ' + hours + ':' + minute;
}
/*
 * 下载文件
 * obj 文件的二进制码
 * name 文件名称
 * suffix 文件格式
 */

export function downloadFile(obj, name, suffix) {
  var url = window.URL.createObjectURL(new Blob([obj]));
  var link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  var fileName = name + timeFormat(new Date()) + '.' + suffix;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
export function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]); // separate out the mime components

  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]; // write the bytes of the string to an ArrayBuffer

  var ab = new ArrayBuffer(byteString.length); // create a view into the buffer

  var ia = new Uint8Array(ab); // set the bytes of the buffer to the correct values

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  } // write the ArrayBuffer to a blob, and you're done


  var blob = new Blob([ab], {
    type: mimeString
  });
  return blob;
}
/**
 * 将时间戳转换为2020/10/20格式
 */

export function timeFormat8(timestamp) {
  //timestamp是整数，否则要parseInt转换,不会出现少个0的情况
  var time = new Date(timestamp);
  var year = time.getFullYear();
  var month = time.getMonth() + 1;
  var date = time.getDate();
  var hours = time.getHours();
  var minutes = time.getMinutes();
  var seconds = time.getSeconds();
  return '' + year + '/' + month + '/' + date;
}
/**
 * 将秒数 转换为 0:00格式
 */

export function formatSeconds(value) {
  var theTime = parseInt(value); // 秒

  var middle = 0; // 分

  var hour = 0; // 小时

  if (theTime > 60) {
    middle = parseInt(theTime / 60);
    theTime = parseInt(theTime % 60);

    if (middle > 60) {
      hour = parseInt(middle / 60);
      middle = parseInt(middle % 60);
    }
  }

  var result = '';

  if (parseInt(theTime) >= 10) {
    if (middle > 0) {
      result = parseInt(middle) + ":" + parseInt(theTime);
    } else {
      result = "00" + ":" + parseInt(theTime);
    }
  } else {
    result = "00" + ":" + "0" + parseInt(theTime);
  }

  return result;
}
/**
 * 特殊字符转码
 * @str 传入的字符串
 */

export function cleanSpelChar(str) {
  if (str) {
    //防止传入空字符报错
    str = str.replace(/&amp;/g, "&");
    str = str.replace(/&nbsp;/g, " ");
    str = str.replace(/&middot;/g, "·");
    str = str.replace(/&lt;/g, "<");
    str = str.replace(/&gt;/g, ">");
    str = str.replace(/&ldquo;/g, "“");
    str = str.replace(/&rdquo;/g, "”");
    str = str.replace(/&rsquo;/g, "'");
    str = str.replace(/&quot;/g, "'");
    str = str.replace(/&hellip;/g, "@");
    str = str.replace(/&sect;/g, "§");
  }

  return str;
}
/**
 * 翻转特殊字符转码
 * @str 传入的字符串
 */

export function unCleanSpelChar(str) {
  if (str) {
    //防止传入空字符报错
    str = str.replace(/&/g, "&amp;");
    str = str.replace(/ /g, "&nbsp;");
    str = str.replace(/·/g, "&middot;");
    str = str.replace(/</g, "&lt;");
    str = str.replace(/>/g, "&gt;");
    str = str.replace(/“/g, "&ldquo;");
    str = str.replace(/”/g, "&rdquo;");
    str = str.replace(/'/g, "&rsquo;");
    str = str.replace(/"/g, "&quot;");
    str = str.replace(/@/g, "&hellip;");
  }

  return str;
} //2.强制保留2位小数，如：2，会在2后面补上00.即2.00

export function toDecimal2(x) {
  var f = parseFloat(x);

  if (isNaN(f)) {
    return false;
  }

  var f = Math.round(x * 100) / 100;
  var s = f.toString();
  var rs = s.indexOf('.');

  if (rs < 0) {
    rs = s.length;
    s += '.';
  }

  while (s.length <= rs + 2) {
    s += '0';
  }

  return s;
}
import * as qiniu from "qiniu-js"; //获取文件后缀名

export function extname(filename) {
  if (!filename || typeof filename != 'string') {
    return false;
  }

  ;
  var a = filename.split('').reverse().join('');
  var b = a.substring(0, a.search(/\./)).split('').reverse().join('');
  return b;
}
;
/*
 * 七牛上传文件
 * @observer 触发的回调函数
 * @formDataNew 上传的文件（form格式）
 * @key 上传的文件的密钥（由接口返回）
 * @token
 */

export function qiniuFun(observer, formDataNew, key, token) {
  var putExtra = {
    fname: formDataNew.name,
    params: {} // mimeType: [] || null,

  };
  var config = {
    useCdnDomain: true,
    region: null
  };
  var observable = qiniu.upload(formDataNew, key, token, putExtra, config);
  var subscription = observable.subscribe(observer); // 上传开始
}
/*
 * 七牛上传图片
 * @observer 触发的回调函数
 * @formDataNew 上传的文件（form格式）
 * @key 上传的文件的密钥（由接口返回）
 * @token
 */

export function qiniuImageFun(observer, formDataNew, key, token) {
  var putExtra = {
    fname: formDataNew.name,
    params: {}
  };
  var config = {
    useCdnDomain: true,
    region: qiniu.region.z1
  };
  var options = {
    quality: 0.92,
    noCompressIfLarger: true // maxWidth: 1000,
    // maxHeight: 618

  };
  qiniu.compressImage(formDataNew, options).then(function (data) {
    var observable = qiniu.upload(data.dist, key, token, putExtra, config);
    var subscription = observable.subscribe(observer); // 上传开始
  });
}
/*
 * htme页面添加meta
 */

export function addMetaElement(name, content) {
  var head = document.getElementsByTagName('head');
  var meta = document.createElement('meta');
  meta.name = name;
  meta.content = content;
  head[0].appendChild(meta);
}
/*
 * html页面删除referrer
 */

export function removeReferrerElement() {
  var head = document.getElementsByTagName('head')[0],
      nodes = head.childNodes,
      node;

  for (var i = nodes.length - 1; i >= 0; i--) {
    node = nodes[i];

    if (node.tagName == 'META' && node.name == "referrer") {
      head.removeChild(node);
    }
  }
}
/*
 * 传参形式改为Form表单形式
 */

export function dataToForm(data) {
  var transformData = null;
  transformData = new FormData();

  for (var key in data) {
    transformData.append(key, data[key]);
  }

  return transformData;
}
/* 是否手机号码*/

export function validatePhone(value, that) {
  // const reg = /^[1][3,4,5,6,7,8][0-9]{9}$/
  // if (value === '' || value === undefined || value == null) {
  //   callback()
  // } else {
  //   if ((!reg.test(value)) && value !== '') {
  //     callback(new Error('请输入正确的电话号码'))
  //   } else {
  //     callback()
  //   }
  // }
  // if (reg.test(value)) {
  return true; // } else {
  // 	that.$message({
  // 		title: '警告',
  // 		message: '请输入正确的手机号码',
  // 		type: 'warning'
  // 	});
  // 	return false
  // }
}
/* 手机号码加密*/

export function encryptPhone(tel) {
  var reg = /^(\d{3})\d{4}(\d{4})$/;
  tel = tel.replace(reg, "$1****$2");
  return tel;
}
/**
 * 空值处理，如果第一个参数为空，则返回第二个参数
 * @param data 判断的对象
 * @param defaultObj 默认对象，如果不传，默认为""
 * @return 处理完以后的对象
 */

export function defaultIfEmpty(data, defaultObj) {
  if (typeof defaultObj == 'undefined') {
    defaultObj = '';
  }

  if (typeof data == 'undefined') {
    return defaultObj;
  }

  if (data) {
    return data;
  } else {
    return defaultObj;
  }
}
/**
 * 如果头像为空,返回默认头像
 * @param avatar 头像地址
 * @param querystring 头像地址
 */

export function defaultAvatarIfEmpty(avatar, querystring) {
  if (!avatar || avatar == "" || typeof avatar == "undefined") {
    return "https://www.openwhy.cn/images/avatar.png";
  }

  if (typeof querystring != "undefined" && querystring) {
    querystring = querystring.replace("?", "");
    avatar = avatar + '?' + querystring;
  }

  return avatar;
}
/**
 * 字符串长度转化为字符长度
 */

export function getStrLength(str) {
  var len = str.length;
  var result = 0;

  for (var i = 0; i < len; i++) {
    if (str.charCodeAt(i) < 27 || str.charCodeAt(i) > 126) {
      result += 2;
    } else {
      result++;
    }
  }

  return result;
}
/**
 * 去空格
 */

export function deleteStrBlank(str) {
  str = str.replace(/^\s+|\s+$/g, "");
  return str;
}
/**
 * 获取链接地址的参数
 * @param {String} name
 */

export function getQueryString(name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  var r = window.location.search.substr(1).match(reg);

  if (r != null) {
    return unescape(r[2]);
  }

  return null;
}
/**
 * 获取富文本中的纯文字
 */

export function getSimpleText(html) {
  var re1 = new RegExp("<.+?>", "g"); //匹配html标签的正则表达式，"g"是搜索匹配多个符合的内容

  var msg = html.replace(re1, ''); //执行替换成空字符

  return msg;
}
/**
 * 判断字符串长度
 */

export function strlen(str) {
  var temp = str.replace(/<\/?.+?>/g, "");
  var result = temp.replace(/ /g, ""); //result为获取冲文字得到后的内容

  return result.length;
}
/**
 * 大数据处理
 */

export function changeToTenThousand(num) {
  num = parseInt(num);

  if (num >= 10000) {
    num = Math.round(num / 1000);
    num = num / 10 + "万";
  }

  return num;
}
/**
 * 毫秒转为时:分:秒
 */

export function formatTime5(mss) {
  if (mss % 1000 > 0) {
    mss = mss * 1 + 1000;
  }

  var hours = parseInt(mss % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
  var minutes = parseInt(mss % (1000 * 60 * 60) / (1000 * 60));
  var seconds = parseInt(mss % (1000 * 60) / 1000);
  return [hours, minutes, seconds];
}
/**
 * 获取图片url后拼接的参数
 */

export function getKeyValue(url, name) {
  var paraString = url.substring(url.indexOf("?") + 1, url.length).split("&");
  var data = new Dictionary();

  for (var i = 0; i < paraString.length; i++) {
    data.put(paraString[i].split("=")[0], paraString[i].split("=")[1]);
  }

  return data.get(name);
}

function Dictionary() {
  this.data = new Array();

  this.put = function (key, value) {
    this.data[key] = value;
  };

  this.get = function (key) {
    return this.data[key];
  };

  this.remove = function (key) {
    this.data[key] = null;
  };

  this.isEmpty = function () {
    return this.data.length == 0;
  };

  this.size = function () {
    return this.data.length;
  };
}

import html2canvas from 'html2canvas';
/**
 * html2canvas
 */

export function createPoster(idName, name, that) {
  // 生成海报
  var vm = this;
  var domObj = document.getElementById(idName);
  html2canvas(domObj, {
    useCORS: true,
    allowTaint: false,
    logging: false,
    letterRendering: true,
    onclone: function onclone(doc) {
      var e = doc.querySelector("#".concat(idName));
      e.style.display = 'block';
    }
  }).then(function (canvas) {
    // 在微信里,可长按保存或转发
    var base64 = canvas.toDataURL('image/png');

    if (that) {
      that.imageList.push(base64);
    } else {
      var elink = document.createElement('a');
      elink.href = base64;
      var timedate = Date.parse(new Date());
      elink.download = "".concat(name, ".png");
      elink.click();
    }
  });
}
/**
 * 去除字符串中的图片
 */

export function deleteImage(content) {
  var imgReg = /<img.*?(?:>|\/>)/gi; //匹配图片中的img标签

  var arr = content.match(imgReg); //筛选出所有的img

  if (arr) {
    arr.map(function (item, index) {
      content = content.split(item);
      content = content[0] + "[\u56FE\u7247".concat(index + 1, "]");
    });
  }

  content = content.replace(/,/g, "");
  content = content.replace(/<[^>]+>/g, "");
  return content;
} //加法

export function accAdd(arg1, arg2) {
  var r1, r2, m;

  try {
    r1 = arg1.toString().split(".")[1].length;
  } catch (e) {
    r1 = 0;
  }

  try {
    r2 = arg2.toString().split(".")[1].length;
  } catch (e) {
    r2 = 0;
  }

  m = Math.pow(10, Math.max(r1, r2));
  return (arg1 * m + arg2 * m) / m;
}
/**
 * 生成uuid方法
 * @returns {string}
 */

export var createUUID = function createUUID() {
  var d = new Date().getTime();

  if (window.performance && typeof window.performance.now === "function") {
    d += performance.now(); //use high-precision timer if available
  }

  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c == 'x' ? r : r & 0x3 | 0x8).toString(16);
  });
  return uuid;
};
export var getStatType = function getStatType(str) {
  var level = {
    '1': '新增用户',
    '2': '新增手机用户',
    '3': '访问次数',
    '4': '访问人数',
    '5': '文章',
    '6': '活动',
    '7': '云协作',
    '8': '商品',
    '9': '文集',
    '10': '圈子',
    '11': '表单',
    '12': '预约',
    '13': '微页面',
    '14': '志愿活动',
    '15': '专栏',
    '16': '微信小程序',
    '17': '手机网站',
    '18': '电脑网站',
    '19': '总用户数',
    '20': '用户总数',
    '21': '报名数',
    '22': '投稿数',
    '23': '评委数',
    '24': '表单数据数量统计',
    '25': '活动最热稿件',
    '26': '活动投票排名',
    '27': '活动选择题',
    '28': '表单选择题',
    '29': '报名数',
    //活动报名投稿趋势
    '30': '投稿数',
    //活动报名投稿趋势
    '31': '填写数',
    //表单填写趋势
    '33': '访问次数',
    //活动访问次数/访问人数
    '34': '访问人数',
    //活动访问次数/访问人数
    '35': '访问次数',
    //表单访问次数/访问人数
    '36': '访问人数',
    //表单访问次数/访问人数
    '37': '活动报名人员',
    '38': '活动荣誉奖项',
    '39': '表单填写人员',
    '40': '交易订单'
  };
  var arr = str.toString().indexOf(',') > -1 ? str.split(',') : str;
  var statType = [];

  if (Array.isArray(arr)) {
    arr.map(function (e) {
      statType.push(level[e]);
    });
  } else {
    statType.push(level[arr]);
  }

  return statType;
};
export var getInItStatisData = function getInItStatisData() {
  // statType 统计类型 statPoints 统计点 statCycle 统计时间 topNum top值 chartType 1、折线图2、柱状图3、饼图4、数量统计5、top列表6、终端展示7、地区区域图
  var options = [{
    "name": "总用户数",
    "statType": 1,
    "chartType": 1,
    "statPoints": 19,
    "statCycle": 2,
    "date": [{
      "name": "今日",
      "value": 0
    }, {
      "name": "昨日",
      "value": 1
    }, {
      "name": "7日",
      "value": 7
    }, {
      "name": "30日",
      "value": 30
    }],
    "key": 0
  }, {
    "name": "新增用户数",
    "statType": 1,
    "chartType": 1,
    "statPoints": "1,2",
    "statCycle": 2,
    "datas": [{
      "name": "新增用户",
      "statPoints": "1"
    }, {
      "name": "新增手机用户",
      "statPoints": "2"
    }],
    "date": [{
      "name": "今日",
      "value": 0
    }, {
      "name": "昨日",
      "value": 1
    }, {
      "name": "7日",
      "value": 7
    }, {
      "name": "30日",
      "value": 30
    }],
    "key": 1
  }, {
    "name": "访问次数/人数",
    "statType": 2,
    "chartType": 2,
    "statPoints": "3,4",
    "statCycle": 2,
    "datas": [{
      "name": "访问次数",
      "statPoints": "3"
    }, {
      "name": "访问人数",
      "statPoints": "4"
    }],
    "date": [{
      "name": "今日",
      "value": 0
    }, {
      "name": "昨日",
      "value": 1
    }, {
      "name": "7日",
      "value": 7
    }, {
      "name": "30日",
      "value": 30
    }],
    "key": 2
  }, {
    "name": "内容总览",
    "statType": 3,
    "chartType": 3,
    "statPoints": "5,6,7,8,9,10,11,12,13,15",
    "statCycle": -1,
    "datas": [{
      "name": "文章",
      "statPoints": "5"
    }, {
      "name": "活动",
      "statPoints": "6"
    }, {
      "name": "圈子",
      "statPoints": "10"
    }, {
      "name": "表单",
      "statPoints": "11"
    }, {
      "name": "微页面",
      "statPoints": "13"
    }, {
      "name": "商品",
      "statPoints": "8"
    }, {
      "name": "文集",
      "statPoints": "9"
    }, {
      "name": "专栏",
      "statPoints": "15"
    }, {
      "name": "预约",
      "statPoints": "12"
    }, {
      "name": "云协作",
      "statPoints": "7"
    }],
    "date": [{
      "name": "全部",
      "value": -1
    }, {
      "name": "今日",
      "value": 0
    }, {
      "name": "昨日",
      "value": 1
    }, {
      "name": "7日",
      "value": 7
    }, {
      "name": "30日",
      "value": 30
    }],
    "key": 3
  }, {
    "name": "内容数量统计",
    "statType": 4,
    "chartType": 4,
    "statPoints": "5,6,7,8,9,10,11,12,13,15,20",
    "statCycle": -1,
    "datas": [{
      "name": "用户总数",
      "statPoints": "20"
    }, {
      "name": "文章",
      "statPoints": "5"
    }, {
      "name": "活动",
      "statPoints": "6"
    }, {
      "name": "圈子",
      "statPoints": "10"
    }, {
      "name": "表单",
      "statPoints": "11"
    }, {
      "name": "微页面",
      "statPoints": "13"
    }, {
      "name": "商品",
      "statPoints": "8"
    }, {
      "name": "文集",
      "statPoints": "9"
    }, {
      "name": "专栏",
      "statPoints": '15'
    }, {
      "name": "预约",
      "statPoints": "12"
    }, {
      "name": "云协作",
      "statPoints": "7"
    }],
    "date": [{
      "name": "全部",
      "value": -1
    }, {
      "name": "今日",
      "value": 0
    }, {
      "name": "昨日",
      "value": 1
    }, {
      "name": "7日",
      "value": 7
    }, {
      "name": "30日",
      "value": 30
    }],
    "key": 4
  }, {
    "name": "活动报名/投稿/评委数量统计",
    "statType": 4,
    "chartType": 4,
    "statPoints": "21,22,23",
    "statCycle": -1,
    "datas": [{
      "name": "报名数",
      "statPoints": "21"
    }, {
      "name": "投稿数",
      "statPoints": "22"
    }, {
      "name": "评委数",
      "statPoints": "23"
    }],
    "date": [{
      "name": "全部",
      "value": -1
    }, {
      "name": "今日",
      "value": 0
    }, {
      "name": "昨日",
      "value": 1
    }, {
      "name": "7日",
      "value": 7
    }, {
      "name": "30日",
      "value": 30
    }],
    "items": {
      "itemId": "",
      "itemName": "",
      "itemType": 1
    },
    "key": 5
  }, {
    "name": "表单数据数量统计",
    "statType": 4,
    "chartType": 4,
    "statPoints": 24,
    "statCycle": -1,
    "date": [{
      "name": "全部",
      "value": -1
    }, {
      "name": "今日",
      "value": 0
    }, {
      "name": "昨日",
      "value": 1
    }, {
      "name": "7日",
      "value": 7
    }, {
      "name": "30日",
      "value": 30
    }],
    "items": {
      "itemId": "",
      "itemName": "",
      "itemType": 2
    },
    "key": 6
  }, {
    "name": "最热文章",
    "statType": 5,
    "chartType": 5,
    "statPoints": 5,
    "statCycle": 2,
    "topNum": 10,
    "date": [{
      "name": "今日",
      "value": 0
    }, {
      "name": "昨日",
      "value": 1
    }, {
      "name": "7日",
      "value": 7
    }, {
      "name": "30日",
      "value": 30
    }],
    "key": 7
  }, {
    "name": "最热活动",
    "statType": 5,
    "chartType": 5,
    "statPoints": 6,
    "statCycle": 2,
    "topNum": 10,
    "date": [{
      "name": "今日",
      "value": 0
    }, {
      "name": "昨日",
      "value": 1
    }, {
      "name": "7日",
      "value": 7
    }, {
      "name": "30日",
      "value": 30
    }],
    "key": 8
  }, {
    "name": "最热活动稿件",
    "statType": 5,
    "chartType": 5,
    "statPoints": 25,
    "statCycle": 2,
    "topNum": 10,
    "date": [{
      "name": "今日",
      "value": 0
    }, {
      "name": "昨日",
      "value": 1
    }, {
      "name": "7日",
      "value": 7
    }, {
      "name": "30日",
      "value": 30
    }],
    "items": {
      "itemId": "",
      "itemName": "",
      "itemType": 1
    },
    "key": 9
  }, {
    "name": "活动投票排名",
    "statType": 5,
    "chartType": 5,
    "statPoints": 26,
    "topNum": 10,
    "items": {
      "itemId": "",
      "itemName": "",
      "itemType": 1
    },
    "key": 10
  }, {
    "name": "最热圈子",
    "statType": 5,
    "chartType": 5,
    "statPoints": 10,
    "statCycle": 2,
    "topNum": 10,
    "date": [{
      "name": "今日",
      "value": 0
    }, {
      "name": "昨日",
      "value": 1
    }, {
      "name": "7日",
      "value": 7
    }, {
      "name": "30日",
      "value": 30
    }],
    "key": 11
  }, {
    "name": "最热文集",
    "statType": 5,
    "chartType": 5,
    "statPoints": 9,
    "statCycle": 2,
    "topNum": 10,
    "date": [{
      "name": "今日",
      "value": 0
    }, {
      "name": "昨日",
      "value": 1
    }, {
      "name": "7日",
      "value": 7
    }, {
      "name": "30日",
      "value": 30
    }],
    "key": 12
  }, {
    "name": "最热商品",
    "statType": 5,
    "chartType": 5,
    "statPoints": 8,
    "statCycle": 2,
    "topNum": 10,
    "date": [{
      "name": "今日",
      "value": 0
    }, {
      "name": "昨日",
      "value": 1
    }, {
      "name": "7日",
      "value": 7
    }, {
      "name": "30日",
      "value": 30
    }],
    "key": 13
  }, {
    "name": "最热表单",
    "statType": 5,
    "chartType": 5,
    "statPoints": 11,
    "statCycle": 2,
    "topNum": 10,
    "date": [{
      "name": "今日",
      "value": 0
    }, {
      "name": "昨日",
      "value": 1
    }, {
      "name": "7日",
      "value": 7
    }, {
      "name": "30日",
      "value": 30
    }],
    "key": 14
  }, {
    "name": "活动选择题",
    "statType": 3,
    "chartType": 3,
    "statPoints": 27,
    "statCycle": -1,
    "date": [{
      "name": "全部",
      "value": -1
    }, {
      "name": "今日",
      "value": 0
    }, {
      "name": "昨日",
      "value": 1
    }, {
      "name": "7日",
      "value": 7
    }, {
      "name": "30日",
      "value": 30
    }],
    "items": {
      "itemId": "",
      "itemName": "",
      "itemType": 1,
      "subJectId": ""
    },
    "chart": 1,
    "key": 15
  }, {
    "name": "表单选择题",
    "statType": 3,
    "chartType": 3,
    "statPoints": 28,
    "statCycle": -1,
    "date": [{
      "name": "全部",
      "value": -1
    }, {
      "name": "今日",
      "value": 0
    }, {
      "name": "昨日",
      "value": 1
    }, {
      "name": "7日",
      "value": 7
    }, {
      "name": "30日",
      "value": 30
    }],
    "items": {
      "itemId": "",
      "itemName": "",
      "itemType": 2,
      "subJectId": ""
    },
    "chart": 1,
    "key": 16
  }, {
    "name": "活动报名投稿趋势",
    "statType": 2,
    "chartType": 1,
    "statPoints": "29,30",
    "statCycle": 2,
    "datas": [{
      "name": "报名数",
      "statPoints": "29"
    }, {
      "name": "投稿数",
      "statPoints": "30"
    }],
    "date": [{
      "name": "今日",
      "value": 0
    }, {
      "name": "昨日",
      "value": 1
    }, {
      "name": "7日",
      "value": 7
    }, {
      "name": "30日",
      "value": 30
    }],
    "items": {
      "itemId": "",
      "itemName": "",
      "itemType": 1
    },
    "key": 17
  }, {
    "name": "表单填写趋势",
    "statType": 2,
    "chartType": 1,
    "statPoints": 31,
    "statCycle": 2,
    "date": [{
      "name": "今日",
      "value": 0
    }, {
      "name": "昨日",
      "value": 1
    }, {
      "name": "7日",
      "value": 7
    }, {
      "name": "30日",
      "value": 30
    }],
    "items": {
      "itemId": "",
      "itemName": "",
      "itemType": 2
    },
    "key": 18
  }, {
    "name": "活动访问次数/访问人数",
    "statType": 2,
    "chartType": 2,
    "statPoints": "33,34",
    "statCycle": 2,
    "datas": [{
      "name": "访问次数",
      "statPoints": "33"
    }, {
      "name": "访问人数",
      "statPoints": "34"
    }],
    "date": [{
      "name": "今日",
      "value": 0
    }, {
      "name": "昨日",
      "value": 1
    }, {
      "name": "7日",
      "value": 7
    }, {
      "name": "30日",
      "value": 30
    }],
    "items": {
      "itemId": "",
      "itemName": "",
      "itemType": 1
    },
    "key": 19
  }, {
    "name": "表单访问次数/访问人数",
    "statType": 2,
    "chartType": 2,
    "statPoints": "35,36",
    "statCycle": 2,
    "datas": [{
      "name": "访问次数",
      "statPoints": "35"
    }, {
      "name": "访问人数",
      "statPoints": "36"
    }],
    "date": [{
      "name": "今日",
      "value": 0
    }, {
      "name": "昨日",
      "value": 1
    }, {
      "name": "7日",
      "value": 7
    }, {
      "name": "30日",
      "value": 30
    }],
    "items": {
      "itemId": "",
      "itemName": "",
      "itemType": 2
    },
    "key": 20
  }, {
    "name": "活动报名人员",
    "statType": 5,
    "chartType": 5,
    "statPoints": 37,
    "statCycle": 2,
    "topNum": 10,
    "date": [{
      "name": "今日",
      "value": 0
    }, {
      "name": "昨日",
      "value": 1
    }, {
      "name": "7日",
      "value": 7
    }, {
      "name": "30日",
      "value": 30
    }],
    "items": {
      "itemId": "",
      "itemName": "",
      "itemType": 1
    },
    "key": 21
  }, {
    "name": "活动荣誉奖项",
    "statType": 5,
    "chartType": 5,
    "statPoints": 38,
    "statCycle": 2,
    "topNum": 10,
    "date": [{
      "name": "今日",
      "value": 0
    }, {
      "name": "昨日",
      "value": 1
    }, {
      "name": "7日",
      "value": 7
    }, {
      "name": "30日",
      "value": 30
    }],
    "items": {
      "itemId": "",
      "itemName": "",
      "itemType": 1
    },
    "key": 22
  }, {
    "name": "表单填写人员",
    "statType": 5,
    "chartType": 5,
    "statPoints": 39,
    "statCycle": 2,
    "topNum": 10,
    "date": [{
      "name": "今日",
      "value": 0
    }, {
      "name": "昨日",
      "value": 1
    }, {
      "name": "7日",
      "value": 7
    }, {
      "name": "30日",
      "value": 30
    }],
    "items": {
      "itemId": "",
      "itemName": "",
      "itemType": 2
    },
    "key": 23
  } // {
  // 	"name": "交易订单",
  // 	"statType": 5,
  // 	"chartType": 5,
  // 	"statPoints": 40,
  // 	"statCycle": 2,
  // 	"topNum": 10,
  // 	"date": [
  // 		{
  // 			"name": "今日",
  // 			"value": 0
  // 		},
  // 		{
  // 			"name": "昨日",
  // 			"value": 1
  // 		},
  // 		{
  // 			"name": "7日",
  // 			"value": 7
  // 		},
  // 		{
  // 			"name": "30日",
  // 			"value": 30
  // 		}
  // 	],
  // 	"key": 24
  // }
  ];
  return options;
}; // 处理获取的内容

export var initItemData = function initItemData(obj, state) {
  if (obj.type == 1) {
    obj.web_img = obj.firstImage ? obj.firstImage : process.env.VUE_APP_BASE_IMG + '/mini-double-column-no-img.png';
    obj.web_title = obj.projectName;
    obj.web_time = state == -1 ? timeFormat4(obj.createTime) : state == 1 ? timeFormat4(obj.auditTime) : timeFormat4(obj.applyTime);
  } else if (obj.type == 3) {
    obj.web_img = obj.articleImg ? obj.articleImg : process.env.VUE_APP_BASE_IMG + '/mini-double-column-no-img.png';
    obj.web_title = obj.title;
    obj.web_time = timeFormat4(state == -1 ? obj.updateTime : state == 1 ? obj.auditTime : obj.applyTime);
  } else if (obj.type == 4) {
    obj.web_img = obj.firstImage ? obj.firstImage : process.env.VUE_APP_BASE_IMG + '/mini-double-column-no-img.png';
    obj.web_title = obj.actName;
    obj.web_time = timeFormat4(state == -1 ? obj.createTime : state == 1 ? obj.auditTime : obj.applyTime);
  } else if (obj.type == 10) {
    obj.web_img = obj.headImg ? obj.headImg : process.env.VUE_APP_BASE_IMG + '/mini-double-column-no-img.png';
    obj.web_title = obj.circleName;
    obj.web_time = timeFormat4(state == -1 ? obj.createTime : state == 1 ? obj.auditTime : obj.applyTime);
  } else if (obj.type == 20) {
    obj.web_img = obj.headUrl ? obj.headUrl : process.env.VUE_APP_BASE_IMG + '/mini-double-column-no-img.png';
    obj.web_title = obj.title;
    obj.web_time = timeFormat4(state == -1 ? obj.createTime : state == 1 ? obj.auditTime : obj.applyTime);
  }

  return obj;
};
/**
 * 当前内容已达数量上限 提示弹层
 * @param {String} mgrPhone 线上中心管理者电话
 * @param {String} platformCustomerServicePhone 平台客服电话
 * @returns 
 */

export function contentLimit(mgrPhone, platformCustomerServicePhone) {
  var str = "\u5F53\u524D\u5185\u5BB9\u5DF2\u8FBE\u6570\u91CF\u4E0A\u9650";

  if (mgrPhone && platformCustomerServicePhone) {
    str = "\u5F53\u524D\u5185\u5BB9\u5DF2\u8FBE\u6570\u91CF\u4E0A\u9650\uFF0C\u8BF7\u8054\u7CFB\u7EBF\u4E0A\u4E2D\u5FC3\u7BA1\u7406\u8005\uFF1A".concat(mgrPhone, "\u6216\u5E73\u53F0\u5BA2\u670D\uFF1A").concat(platformCustomerServicePhone);
  } else if (mgrPhone && !platformCustomerServicePhone) {
    str = "\u5F53\u524D\u5185\u5BB9\u5DF2\u8FBE\u6570\u91CF\u4E0A\u9650\uFF0C\u8BF7\u8054\u7CFB\u7EBF\u4E0A\u4E2D\u5FC3\u7BA1\u7406\u8005\uFF1A".concat(mgrPhone);
  } else if (!mgrPhone && platformCustomerServicePhone) {
    str = "\u5F53\u524D\u5185\u5BB9\u5DF2\u8FBE\u6570\u91CF\u4E0A\u9650\uFF0C\u8BF7\u8054\u7CFB\u5E73\u53F0\u5BA2\u670D\uFF1A".concat(platformCustomerServicePhone);
  } else {
    str = "\u5F53\u524D\u5185\u5BB9\u5DF2\u8FBE\u6570\u91CF\u4E0A\u9650";
  }

  return str;
}